import Link from 'next/link';
import PropTypes from 'prop-types';
import Styles from './HomeAbout.module.scss';
export default function HomeAbout() {
    return (
        <section className={`section ${Styles.about}`}>
            <div className="container has-text-centered">
                <h3 className="title is-unselectable has-text-primary mb-6">About me</h3>
                <hr />

                <div className="columns">
                    <div className="column is-8 is-offset-2">
                        <p className="has-text-grey-dark is-size-5 mb-3">
                            My name is Bruno Domingos and I am a proactive and focused developer
                            with ten years’ experience in a variety of development positions with a
                            personal taste for frontend development.
                        </p>
                        <p className="has-text-grey-dark is-size-5 mb-6">
                            Organised, curious, passionate and a keen eye for detail.
                        </p>
                        <p>
                            <Link href="/about">
                                <a className="button is-info is-medium">Learn more about me</a>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

HomeAbout.propTypes = {
    active: PropTypes.string,
    tags: PropTypes.object,
};
