import { tags } from '@garlink/bd-pkg-portfolio';

export const fetcher = async url => {
    const res = await fetch(url);
    // console.log('🚀 ~ file: functions.js ~ line 5 ~ res', res);

    if (!res.ok) {
        // console.log('🚀 ~ file: functions.js ~ line 5 ~ res 1', res);
        const error = new Error('An error occurred while fetching the data.');
        error.info = await res.json();
        error.status = res.status;
        throw error;
    }

    // console.log('🚀 ~ file: functions.js ~ line 5 ~ res 2', res);
    if (res.status === 204) {
        try {
            const error = new Error('No content.');
            error.info = await res.json();
            error.status = res.status;
            throw error;
            // return res.json({ message: 'cenas e coisas' });
        } catch (error) {
            // console.log('Error try catch');
        }
    }

    // return res.json();
    const data = await res.json();
    return data;
};

export const getTagBySlug = slug => {
    const tag = tags.filter(t => t.slug === slug);
    return tag && tag[0] ? tag[0] : '';
};

export const getTagByName = name => {
    const tag = tags.filter(t => t.name === name);
    return tag && tag[0] ? tag[0] : '';
};

export const getTagsByProject = name => {
    const tag = tags.filter(t => t.name === name);
    return tag && tag[0] ? tag[0] : '';
};

export const toBase64 = str =>
    typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

export const shimmerEffect = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#dee2e6" offset="20%" />
      <stop stop-color="#e9ecef" offset="50%" />
      <stop stop-color="#dee2e6" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#dee2e6" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export const shimmerEffectDark = (w, h) => `
<svg viewBox="0 0 ${w} ${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <circle cx="${w}" cy="${h}" r="${h}" fill="black" />
</svg>`;
