import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';
import Styles from './Header.module.scss';
import { shimmerEffectDark, toBase64 } from 'utils/functions';
// import Badge from '../../public/images/badge-min.png';

export default function Header({ section }) {
    if (section === 'home') {
        return (
            <header
                className={`container is-fluid text-center ${Styles.header} ${Styles.home} hero is-primary is-bold`}
            >
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <Image
                            src="/images/badge-min.png"
                            // src={Badge}
                            alt="Bruno Domingos"
                            width={80}
                            height={80}
                            layout="fixed"
                            loading="eager"
                            placeholder="blur"
                            blurDataURL={`data:image/svg+xml;base64,${toBase64(
                                shimmerEffectDark(80, 80),
                            )}`}
                        />

                        <h1 className="title is-uppercase mt-3 is-unselectable">Bruno Domingos</h1>
                        <h2 className="subtitle has-text-info is-unselectable">
                            doing stuff for the web since 2010
                        </h2>
                    </div>
                </div>
            </header>
        );
    }
    return (
        <header className={`container is-fluid text-center ${Styles.header} hero is-primary`}>
            <Link href="/">
                <a>
                    <Image
                        src="/images/badge-min.png"
                        // src={Badge}
                        alt="Bruno Domingos"
                        width={50}
                        height={50}
                        layout="fixed"
                        loading="eager"
                        placeholder="blur"
                        blurDataURL={`data:image/svg+xml;base64,${toBase64(
                            shimmerEffectDark(50, 50),
                        )}`}
                    />
                </a>
            </Link>
        </header>
    );
}

Header.propTypes = {
    section: PropTypes.string,
};
